import React, { Component } from 'react';
import { Container } from 'semantic-ui-react';

export class AboutUs extends Component {
  render() {
    return (
      <Container className="document-details-container default-margin-top">
        <h1>Welcome!</h1>
        <p>
          Welcome to the card catalog of books maintained by the Servants of
          Knowledge Association. Here you can search metadata and full text of
          books we have scanned or copied from other sources on the net. This
          system does not give access to the books themselves, but where the
          books are available for general use, we furnish you a pointer to the
          books. Note that in addition to books, this system catalogs movies,
          audio, pictures, and other datatypes. You can also visit our{' '}
          <a href="https://archive.org/details/JaiGyan?and%5B%5D=mediatype%3A%22collection%22">
            JaiGyan Collection{' '}
          </a>
          on the Internet Archive.
        </p>
        <h1>About the Servants of Knowledge</h1>
        <p>
          The Anubhavba Mantapa Servants of Knowledge Association is a Section 8
          nonprofit association formed in 2024 under the laws of India. We are
          certified under Section 12(a) and 80(g) to receive tax deductible
          donations under the Indian Income Tax Act of 1961. We are also
          certified to receive Corporate Social Responsibility (CSR)
          contributions. We are <i>not</i> qualified to received foreign
          contributions under the Foreign Contributions Reporting Act.
        </p>
        <p>The co-founders of the Servants of Knowledge Association are:</p>
        <ul>
          <li>
            <a href="https://en.wikipedia.org/wiki/Lawrence_Liang">
              Dr. Lawrence Liang{' '}
            </a>
            serves as chairman of the board of trustees. He is the Founding Dean
            and Professor of Law at the Ambedkar University, Delhi School of
            Law, Governance and Citizenship and is the recipient of the Infosys
            Prize. Dr. Lang is a graduate of the National Law School India
            University and has received a Ph.D. in Cinema Studies from JNU.
          </li>
          <li>
            <a href="https://www.linkedin.com/in/omshivaprakash/">
              Omshivaprakash HL{' '}
            </a>
            serves on the board of trustees and manages the operations of the
            Association. He is the co-founder of Sanchaya - Kannada Language
            Technology Research &amp; Study Forum and the Sanchi Foundation.
            Omshivaprakash has worked on numerous open source and open knowledge
            projects and his work has been widely recognized in Karnataka.
          </li>
          <li>
            <a href="https://www.linkedin.com/in/sushant354/">
              Dr. Sushant Sinha{' '}
            </a>
            serves as secretary/treasurer of the board of trustees and as our
            chief technology officer. Dr. Sinha is the founder of{' '}
            <a href="https://indiankanoon.org/">Indian Kanoon.</a> Dr. Sinha
            received his bachelor and master's degrees from IIT Madras and his
            Ph.D. in Computer Science from the University of Michigan.
          </li>
          <li>
            <a href="https://en.wikipedia.org/wiki/Carl_Malamud">
              Carl Malamud{' '}
            </a>
            serves as honorary secretary of the Association. He is the founder
            of <a href="https://public.resource.org/">Public.Resource.Org</a>{' '}
            and is the recipient of the Berkman Award from Harvard University
            and the Pioneer Award from the Electronic Frontier Foundation. He is
            the author of 9 books.
          </li>
        </ul>
        <p>
          The Servants of Knowledge has been in operation as a voluntary
          association since 2015. Our first home was at the Indian Academy of
          Sciences. We were subsequently based at the National Law School of
          India University and are now located at Gandhi Bhavan in Bengaluru. We
          have worked with a large number of organisations in India. Our work
          has included digitisation of the entire National Law School of India
          University library, the Gandhi Bhavan Library, and many others. Some
          of the other organisations we have worked with include Roja Muthiah
          Library in Chennai, Vishwakonkani Centre in Mangaluru, Kannada
          University in Hampi, as well as Sochara Community Health Organisation,
          Ramkrishna Ashram, Azim Premji University, and Lalbagh Botanical
          Gardens.
        </p>
        <h1>Our Digitisation Efforts</h1>
        <p>
          Our scan centre in the Gandhi Bhavan has a capacity of 10-15 lakh
          pages per month. We brought scanners to the 2023 Bangalore Literature
          Festival and did a live demonstration. You can see them in operation
          in this video!
        </p>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/07u5lwh9Emw?si=DF_iXdQXsN-4_oB-"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowFullScreen
        />
        <h1>Servants of Knowledge in the News</h1>
        <ul>
          <li>
            Bangalore Mirror,{' '}
            <a href="https://bangaloremirror.indiatimes.com/bangalore/others/gandhis-teachings-a-click-closer/articleshow/112769155.cms">
              Gandhi’s teachings, a click closer
            </a>
            , August 25, 2024.
          </li>
          <li>
            Deccan Herald,{' '}
            <a href="https://www.deccanherald.com/india/karnataka/digital-repository-of-literature-1024570.html">
              Digital repository of literature
            </a>
            , August 28, 2021.
          </li>
          <li>
            Deccan Herald,{' '}
            <a href="https://www.deccanherald.com/india/karnataka/nlsiu-to-set-benchmark-with-library-digitisation-1081227.html">
              NLSIU to set benchmark with library digitisation
            </a>
            , February 14, 2022.
          </li>
          <li>
            Indian Express,{' '}
            <a href="https://indianexpress.com/article/cities/bangalore/books-digitised-bengaluru-all-india-gandhi-library-project-9533742/">
              Close to 12,000 books digitised in Bengaluru for All India Gandhi
              Library project
            </a>
            , August 26, 2024.
          </li>
          <li>
            Internet Archive,{' '}
            <a href="https://blog.archive.org/2019/10/20/jai-hind-jai-gyan-india-on-the-internet-archive/">
              Jai Hind! Jai Gyan! India on the Internet Archive
            </a>
            , October 20, 2019.
          </li>
          <li>
            MIT Technology Review,{' '}
            <a href="https://www.technologyreview.com/2023/10/25/1081572/india-digitize-rare-documents-internet-archive-library-servants-of-knowledge/">
              The grassroots push to digitize India’s most precious documents
            </a>
            , October 25, 2023.
          </li>
          <li>
            The Hindu,{' '}
            <a href="https://www.thehindu.com/news/cities/bangalore/11700-books-at-gandhi-smaraka-nidhi-digitised/article68566505.ece">
              11,700 books from Karnataka Gandhi Smaraka Nidhi Library digitised
            </a>
            , August 26, 2024.
          </li>
          <li>
            The Hindu,{' '}
            <a href="https://www.thehindu.com/news/cities/bangalore/bengalurus-lalbagh-library-to-take-digital-form-soon/article68428814.ece">
              Bengaluru’s Lalbagh library to take digital form soon
            </a>
            , July 21, 2024.
          </li>
          <li>
            The Hindu,{' '}
            <a href="https://www.thehindu.com/news/cities/bangalore/scanning-is-the-new-spinning-for-this-gandhian-literature-initiative/article67366466.ece">
              ‘Scanning is the new spinning’ for this Gandhian literature
              initiative
            </a>
            , October 1, 2023.
          </li>
          <li>
            The Hindu,{' '}
            <a href="https://www.thehindu.com/news/national/karnataka/gandhi-bhavan-in-bengaluru-to-get-their-digitised-archival-material-during-seminar-on-august-24-and-25/article68558404.ece">
              Gandhi Bhavan in Bengaluru to get their digitised archival
              material during seminar on August 24 and 25
            </a>
            , August 23, 2024.
          </li>
          <li>
            The Hindu,{' '}
            <a href="https://www.thehindu.com/news/national/karnataka/servants-of-knowledge-to-digitally-archive-motilal-banarsidass-out-of-copyright-books/article67547290.ece">
              Servants of Knowledge to digitally archive Motilal Banarsidass’
              out-of-copyright books
            </a>
            , November 19, 2023.
          </li>
          <li>
            The News Minute,{' '}
            <a href="https://www.thenewsminute.com/karnataka/forgotten-kannada-literature-these-groups-are-turning-digital-saviours-144223">
              For forgotten Kannada literature, these groups are turning into
              digital saviours
            </a>
            , February 25, 2021.
          </li>
          <li>
            Your Story,{' '}
            <a href="https://yourstory.com/2019/05/digital-public-library-american-carl-malamud">
              Meet the American who is creating a high-quality digital public
              library in India
            </a>
            , May 30, 2019.
          </li>
        </ul>
      </Container>
    );
  }
}
